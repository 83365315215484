import { Component } from '@angular/core';

@Component({
  selector: 'app-new-auth',
  templateUrl: './new-auth.component.html',
  styleUrl: './new-auth.component.scss'
})
export class NewAuthComponent {

}
